import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { Document, Page, pdfjs } from 'react-pdf';
import mammoth from 'mammoth';
import { jsPDF } from 'jspdf'; // Import jsPDF
import { FaTimes, FaDownload } from 'react-icons/fa'; // Import icons
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;

Modal.setAppElement('#root');

const SummaryPopup = ({ isOpen, onClose, summaryData }) => {
  const { summary, clauses, negotiation_questions, file } = summaryData;
  const [pdfData, setPdfData] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [docFileUrl, setDocFileUrl] = useState(null);
  const [textContent, setTextContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('summary');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showDocument, setShowDocument] = useState(true); // State to toggle document visibility

  // Use a ref to store the previous docFileUrl for cleanup
  const prevDocFileUrlRef = useRef();

  // Process the file when it changes
  useEffect(() => {
    if (!file) {
      setPdfData(null);
      setDocFileUrl(null);
      setTextContent('');
      setLoading(false);
      return;
    }

    const processFile = async () => {
      setLoading(true);
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (fileExtension === 'pdf') {
        const reader = new FileReader();
        reader.onload = (e) => {
          setPdfData(e.target.result);
          setLoading(false);
        };
        reader.readAsDataURL(file);
      } else if (fileExtension === 'docx') {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          const arrayBuffer = e.target.result;
          mammoth.extractRawText({ arrayBuffer })
            .then((result) => {
              setTextContent(result.value);
              setLoading(false);
            })
            .catch((error) => {
              console.error("Error extracting .docx content:", error);
              setLoading(false);
            });
        };
        fileReader.readAsArrayBuffer(file);
      } else if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
        const docUrl = URL.createObjectURL(file);
        setDocFileUrl(docUrl);
        setLoading(false);
      } else if (fileExtension === 'txt') {
        const reader = new FileReader();
        reader.onload = (e) => {
          setTextContent(e.target.result);
          setLoading(false);
        };
        reader.readAsText(file);
      }
    };

    processFile();
  }, [file]); // Only re-run if `file` changes

  // Clean up the previous docFileUrl when it changes
  useEffect(() => {
    const prevDocFileUrl = prevDocFileUrlRef.current;
    if (prevDocFileUrl) {
      URL.revokeObjectURL(prevDocFileUrl); // Clean up previous URL
    }
    prevDocFileUrlRef.current = docFileUrl; // Store the current URL for future cleanup
  }, [docFileUrl]);

  // Clean up the last docFileUrl when the component unmounts
  useEffect(() => {
    return () => {
      if (prevDocFileUrlRef.current) {
        URL.revokeObjectURL(prevDocFileUrlRef.current); // Clean up on unmount
      }
    };
  }, []);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Function to bold key details (before ":") while keeping values normal
  const formatTextWithBoldKey = (text) => {
    if (!text.includes(':')) return text; // If no ":" exists, return as is
    const parts = text.split(':');
    const keyDetail = parts[0]?.trim(); // Extract key detail
    const value = parts.slice(1).join(':').trim(); // Extract value (preserve ":" if present in value)

    return (
      <>
        <strong>{keyDetail}</strong>: {value}
      </>
    );
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const renderDocumentViewer = () => {
    if (!file) return null;
    if (loading) return <p>Loading document...</p>;

    const fileExtension = file.name.split('.').pop().toLowerCase();

    switch (fileExtension) {
      case 'pdf':
        return pdfData ? (
          <div style={{ maxHeight: '75vh', overflowY: 'auto', width: '100%' }}>
            <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} width={isMobile ? 300 : 680} />
              ))}
            </Document>
          </div>
        ) : (
          <p>Error loading PDF.</p>
        );
      case 'docx':
        return (
          <div style={{ maxHeight: '75vh', overflowY: 'auto', width: '100%' }}>
            <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
              {textContent}
            </pre>
          </div>
        );
      case 'jpg':
      case 'jpeg':
      case 'png':
        return docFileUrl ? (
          <div style={{ maxHeight: '75vh', overflowY: 'auto', width: '100%' }}>
            <img src={docFileUrl} alt="Uploaded document" style={{ maxWidth: '100%', height: 'auto' }} />
          </div>
        ) : (
          <p>Error loading image.</p>
        );
      case 'txt':
        return (
          <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', maxHeight: '75vh', overflowY: 'auto' }}>
            {textContent}
          </pre>
        );
      default:
        return <p>Unsupported file type.</p>;
    }
  };

  // Function to generate and download the PDF
  const handleDownloadPDF = () => {
    const doc = new jsPDF();

    // Set margins and line height
    const margin = 10;
    const lineHeight = 7;
    let yPos = margin;

    // Function to add text with wrapping
    const addTextWithWrapping = (text, fontSize, isBold = false) => {
      doc.setFontSize(fontSize);
      if (isBold) doc.setFont(undefined, 'bold');
      else doc.setFont(undefined, 'normal');

      const pageWidth = doc.internal.pageSize.getWidth();
      const maxWidth = pageWidth - 2 * margin;

      // Split text into lines that fit within the page width
      const lines = doc.splitTextToSize(text, maxWidth);

      // Add each line to the PDF
      lines.forEach((line) => {
        if (yPos + lineHeight > doc.internal.pageSize.getHeight() - margin) {
          doc.addPage(); // Add a new page if the current line exceeds the page height
          yPos = margin; // Reset yPos for the new page
        }
        doc.text(line, margin, yPos);
        yPos += lineHeight;
      });

      yPos += lineHeight; // Add extra space after each section
    };

    // Add summary
    addTextWithWrapping('Summary', 16, true);
    addTextWithWrapping(summary || 'No summary available.', 12);

    // Add Key Terms (previously Clauses)
    addTextWithWrapping('Key Terms', 16, true);
    if (clauses && Array.isArray(clauses) && clauses.length > 0) {
      clauses.forEach((clause) => {
        addTextWithWrapping(clause, 12);
      });
    } else {
      addTextWithWrapping('No key terms available.', 12);
    }

    // Add Key Concerns (previously Q&A)
    addTextWithWrapping('Key Concerns', 16, true);
    if (negotiation_questions && Array.isArray(negotiation_questions) && negotiation_questions.length > 0) {
      negotiation_questions.forEach((question) => {
        addTextWithWrapping(question, 12);
      });
    } else {
      addTextWithWrapping('No key concerns available.', 12);
    }

    // Generate the PDF filename
    const originalFileName = file ? file.name.split('.').slice(0, -1).join('.') : 'document'; // Remove file extension
    const pdfFileName = `${originalFileName}_summary.pdf`;

    // Save the PDF
    doc.save(pdfFileName);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Summary Popup"
      style={{
        overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: isMobile ? '90%' : file ? '1200px' : '600px',
          maxHeight: '95vh',
          width: isMobile ? '100%' : file ? '1200px' : '600px',
          padding: '20px',
          overflow: 'hidden',
        },
      }}
    >
      <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '20px', maxHeight: '85vh', overflowY: 'hidden' }}>
        {/* Toggle Button (Mobile Only) */}
        {isMobile && (
          <button
            onClick={() => setShowDocument(!showDocument)}
            style={{
              position: 'fixed',
              top: '10px',
              right: '10px',
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
              zIndex: 1000, // Ensure it's above other content
            }}
          >
            {showDocument ? '−' : '+'} {/* Minus sign to collapse, plus sign to expand */}
          </button>
        )}

        {file && showDocument && (
          <div style={{ flex: isMobile ? 'auto' : 2, borderRight: isMobile ? 'none' : '1px solid #ddd', paddingRight: isMobile ? '0' : '20px', overflowY: 'auto' }}>
            <h3>{file ? file.name : 'Document'}</h3>
            <div style={{ maxHeight: '75vh', overflowY: 'auto' }}>
              {renderDocumentViewer()}
            </div>
          </div>
        )}

        <div style={{ flex: isMobile ? 'auto' : 1, maxWidth: isMobile ? '100%' : file ? '400px' : '100%', overflowY: 'auto' }}>
          {/* Tabs (Sticky on Mobile) */}
          <div
            style={{
              display: 'flex',
              borderBottom: '2px solid #ddd',
              marginBottom: '10px',
              position: isMobile ? 'sticky' : 'static',
              top: 0,
              backgroundColor: 'white',
              zIndex: 1, // Ensure tabs stay above the content
            }}
          >
            <button
              style={{
                flex: 1,
                padding: '10px',
                cursor: 'pointer',
                backgroundColor: activeTab === 'summary' ? '#6f6f6f' : 'white',
                color: activeTab === 'summary' ? 'white' : '#333',
                border: 'none',
                borderBottom: activeTab === 'summary' ? '2px solidrgb(231, 233, 235)' : 'none'
              }}
              onClick={() => setActiveTab('summary')}
            >
              Summary
            </button>
            <button
              style={{
                flex: 1,
                padding: '10px',
                cursor: 'pointer',
                backgroundColor: activeTab === 'clauses' ? '#6f6f6f' : 'white',
                color: activeTab === 'clauses' ? 'white' : '#333',
                border: 'none',
                borderBottom: activeTab === 'clauses' ? '2px solidrgb(231, 233, 235)' : 'none'
              }}
              onClick={() => setActiveTab('clauses')}
            >
              Key Terms
            </button>
            <button
              style={{
                flex: 1,
                padding: '10px',
                cursor: 'pointer',
                backgroundColor: activeTab === 'negotiation' ? '#6f6f6f' : 'white',
                color: activeTab === 'negotiation' ? 'white' : '#333',
                border: 'none',
                borderBottom: activeTab === 'negotiation' ? '2px solidrgb(231, 233, 235)' : 'none'
              }}
              onClick={() => setActiveTab('negotiation')}
            >
              Key Concerns
            </button>
          </div>

          {/* Output (Scrollable) */}
          <div style={{ lineHeight: '1.4', textAlign: 'left', maxHeight: '65vh', overflowY: 'auto' }}>
            {activeTab === 'summary' && (
              <div>
                {summary ? summary.split('\n').map((line, index) => <p key={index} style={{ marginBottom: '8px' }}>{formatTextWithBoldKey(line.trim())}</p>) : <p>No summary available.</p>}
              </div>
            )}
            {activeTab === 'clauses' && (
              <div>
                {clauses && Array.isArray(clauses) && clauses.length > 0 ? (
                  clauses.map((clause, index) => <p key={index}>{formatTextWithBoldKey(clause)}</p>)
                ) : (
                  <p>No key terms available.</p>
                )}
              </div>
            )}
            {activeTab === 'negotiation' && (
              <div>
                {negotiation_questions && Array.isArray(negotiation_questions) && negotiation_questions.length > 0 ? (
                  negotiation_questions.map((question, index) => <p key={index}>{formatTextWithBoldKey(question)}</p>)
                ) : (
                  <p>No key concerns available.</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Buttons */}
      {isMobile ? (
        // Mobile UI: Icons
        <div
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            display: 'flex',
            gap: '10px',
            zIndex: 1000,
          }}
        >
          {/* Download Button */}
          <button
            onClick={handleDownloadPDF}
            style={{
              padding: '10px',
              backgroundColor: '#28a745',
              color: 'white',
              border: 'none',
              borderRadius: '50%',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            <FaDownload size={20} />
          </button>

          {/* Close Button */}
          <button
            onClick={onClose}
            style={{
              padding: '10px',
              backgroundColor: '#dc3545',
              color: 'white',
              border: 'none',
              borderRadius: '50%',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            <FaTimes size={20} />
          </button>
        </div>
      ) : (
        // Web UI: Text Buttons
        <>
          <button
            onClick={handleDownloadPDF}
            style={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              padding: '10px 20px',
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              fontSize: '16px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              zIndex: 1000,
            }}
          >
            Download PDF
          </button>

          <button
            onClick={onClose}
            style={{
              marginTop: isMobile ? '-10px' : '20px', // Moves the button up on mobile
              padding: '10px 20px',
              backgroundColor: '#ff4d4d',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              position: 'sticky',
              bottom: isMobile ? '5px' : '0', // Adjusts visibility for mobile
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 10, // Ensure it's above other content
            }}
          >
            Close
          </button>
        </>
      )}
    </Modal>
  );
};

export default SummaryPopup;