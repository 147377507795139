import React, { useState, useRef } from "react";
import { FaPlus } from "react-icons/fa"; // Importing the Plus icon
import "./App.css";
import SummaryPopup from "./SummaryPopup";
import heic2any from "heic2any"; // Import heic2any for HEIC conversion

function App() {
  const [file, setFile] = useState(null);
  const [text, setText] = useState("");
  const [summaryData, setSummaryData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    // Convert HEIC to JPEG if necessary
    if (fileExtension === "heic") {
      try {
        const convertedBlob = await heic2any({
          blob: selectedFile,
          toType: "image/jpeg", // Convert to JPEG
          quality: 0.8, // Optional: Set image quality (0 to 1)
        });

        // Create a new file object with the converted blob
        const convertedFile = new File([convertedBlob], selectedFile.name.replace(/\.heic$/i, ".jpg"), {
          type: "image/jpeg",
        });

        setFile(convertedFile);
      } catch (error) {
        console.error("Error converting HEIC to JPEG:", error);
        setError("Failed to convert HEIC file. Please upload a JPEG or PNG file.");
        return;
      }
    } else {
      setFile(selectedFile);
    }

    setText("");
    setError("");
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setError("");
  };

  const handleRemoveFile = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file && !text) {
      setError("Please upload a file or paste text to summarize.");
      return;
    }

    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const supportedFileTypes = ["txt", "pdf", "docx", "png", "jpg", "jpeg", "heic"]; // Added .heic
      if (!supportedFileTypes.includes(fileExtension)) {
        setError("Unsupported file type. Please upload a .txt, .pdf, .docx, .png, .jpg, or .heic file.");
        return;
      }

      if (file.size > 10 * 1024 * 1024) {
        setError("File size exceeds the limit of 10MB.");
        return;
      }
    }

    setIsLoading(true);
    setError("");
    setSummaryData(null);

    try {
      const functionUrl = process.env.REACT_APP_FUNCTION_URL;
      let response;

      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        response = await fetch(functionUrl, {
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
          },
        });
      } else {
        response = await fetch(functionUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ text }),
        });
      }

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      if (result.error) {
        setError(result.error);
      } else {
        setSummaryData({
          summary: result.summary,
          clauses: result.clauses,
          negotiation_questions: result.negotiation_questions,
          file: file || null,
        });
        setIsPopupOpen(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Error: Unable to summarize the document. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="App">
      <header className="header">
      <img
        src="/quickterm-logo.png" // Path to the logo in the public directory
        alt="Quick Terms Logo"
        className="app-logo"
        width={300}
      />        
      <h2 className="app-subtitle">Quickly understand any document with Quick Terms!</h2>
      <p className="app-subtitle">
      Capture, upload, and instantly summarize text, reports, articles, and more with AI—whether as files, text, or photos—so you can focus on what matters.
      </p>
      </header>
      <form onSubmit={handleSubmit} className="form-container">
        <div className="input-section">
          {/* File Upload Box */}
          <label htmlFor="file-upload" className="file-upload-box">
            <div className="upload-left">
              <FaPlus className="upload-plus-icon" />
              <span className="upload-text">{file ? file.name : " Upload Document"}</span>
            </div>
            <input
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              ref={fileInputRef}
              accept=".pdf,.txt,.docx,.jpg,.jpeg,.png,.heic" // Added .heic
              disabled={!!text}
              className="file-input"
            />
          </label>
          {file && (
            <button type="button" className="remove-file-btn" onClick={handleRemoveFile}>
              ✖
            </button>
          )}

          <p className="or-text">OR</p>
          <textarea
            id="text-input"
            placeholder="Paste your text here..."
            value={text}
            onChange={handleTextChange}
            rows={5}
            disabled={!!file}
            className="text-input"
          />
        </div>
        <button type="submit" className="submit-btn" disabled={isLoading}>
          {isLoading ? <div className="spinner"></div> : "Summarize"}
        </button>
      </form>
      {error && <p className="error">{error}</p>}
      <SummaryPopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        summaryData={summaryData || { summary: "", clauses: [], negotiation_questions: [], file: null }}
      />
      <div className="disclaimer">
        <p>
        Quick Terms uses AI technology to summarize documents for informational purposes only. 
        Summaries may not always be complete or accurate. <br />
        For professional advice related to your document, consult a qualified expert.
        </p>
      </div>
    </div>
  );
}

export default App;